<template>
  <div
    v-if="Login"
    class="login"
  >
    <div
      ref="measure"
      class="measure"
    >
      {{measureText}}
    </div>
    <MarkdownBlock
      class="intro"
      :text="Login.loginIntro"
    />
    <form
      class="mb-5"
      @submit.prevent="onSubmit"
    >
      <div>
        <label for="email">{{Login.loginPreMail}}</label>
        <input
          name="email"
          type="text"
          ref="email"
          required
          v-model="email"
          :placeholder="Login.loginMailPlaceholder"
          :style="{'font-size': emailFont}"
          :class="{error: emailError}"
        />
      </div>
      <div class="error-message error mt-2" :class="{visible: emailError}">
        <small>
          {{ $language.current === 'en'
            ? 'Hi! It looks like your email isn’t registered yet! To get access, please'
            : 'Ciao! Sembra che la tua mail non sia registrata! Per avere accesso, '
          }}
          <a
            rel="noopener"
            target="_blank"
            :href="Login.loginRegisterUrl"
            style="text-decoration: underline;"
          >
            {{ $language.current === 'en'
              ? 'register here'
              : 'registrati qui'
            }}
          </a>
        </small>
      </div>
      <!-- <div>
        <label for="password">{{Login.loginPrePassword}}</label>
        <input
          name="password"
          type="password"
          ref="password"
          required
          v-model="password"
          :placeholder="Login.loginPasswordPlaceholder"
          :style="{'font-size': passwordFont}"
        />
      </div> -->
      <div class="cta-wrapper">
        <Cta
          class="submit mb-3"
          :buttonType="'submit'"
          :disabled="email.length < 1 || waitApi"
          :loading="waitApi"
        >
          {{Login.loginSubmitLabel}}
        </Cta>
        <a
          rel="noopener"
          target="_blank"
          :href="Login.loginRegisterUrl"
        >
          {{Login.loginRegisterLabel}}
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { Viewport, MarkdownBlock } from '@monogrid/vue-lib'
import LoginQuery from '@/graphql/GetLogin.gql'
import Cta from '@/atoms/Cta'

export default {
  name: 'LoginPage',
  mixins: [Viewport],
  components: { MarkdownBlock, Cta },
  data () {
    this.fontMax = this.$root.platform.desktop ? 10 : 8
    this.fontMin = 2
    this.fontStep = 0.1
    return {
      emailFont: this.fontMax,
      passwordFont: this.fontMax,
      measureText: '',
      email: '',
      password: '',
      emailError: false,
      waitApi: false
    }
  },
  apollo: {
    Login: {
      query: LoginQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: '757eb7dc-6ba0-4905-8bd1-5a19a03e1fa2',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      }
    }
  },
  watch: {
    Login: {
      immediate: true,
      handler (value) {
        if (value) {
          this.$root.preloader.visible = false

          this.$root.sendEvent({
            event: 'show', // Event type [default = 'interaction'] (Optional)
            category: 'login',
            action: 'show',
            label: 'Show login page'
          })
        }
      }
    },
    async viewPort () {
      await this.calculateFont('email')
      await this.calculateFont('password')
    },
    email () {
      this.calculateFont('email')
      this.emailError = false
    },
    password () {
      this.calculateFont('password')
    }
  },
  methods: {
    async calculateFont (ref) {
      if (!this.Login || !this[ref]) return
      let textToMeasure = this[ref]
      if (textToMeasure.length === 0) {
        textToMeasure = ref === 'password' ? this.Login.loginPasswordPlaceholder : this.Login.loginMailPlaceholder
      } else {
        textToMeasure = ref === 'password' ? new Array(textToMeasure.length + 1).join('•') : textToMeasure
      }
      this.measureText = textToMeasure
      await this.$nextTick()
      let iteration = 0
      let fontSize
      do {
        fontSize = this.fontMax - this.fontStep * iteration
        this.$refs.measure.style.fontSize = `${fontSize}vh`
        if (fontSize < this.fontMin) break
        else iteration++
        await this.$nextTick()
      } while (this.$refs.measure.clientWidth > this.$refs[ref].clientWidth)

      this[`${ref}Font`] = `${fontSize}vh`
    },
    async onSubmit (e) {
      this.waitApi = true

      this.$root.sendEvent({
        event: 'interaction',
        category: 'login',
        action: 'submit',
        label: 'Submit login form'
      })
      try {
        const response = await axios.post('/api/gate', { email: this.email.trim() })

        if (response.status === 200) {
          this.$root.userIsLogged = this.email.trim()
          document.cookie = `fedUs=${this.email.trim()}; path=/; max-age=${60 * 60 * 24 * 5};`
          this.$root.sendEvent({
            event: 'api_response',
            category: 'login',
            // action: 'logged ok',
            action: this.email.trim(),
            label: 'User logged in'
          })
        } else {
          this.emailError = true
        }
      } catch (err) {
        this.emailError = true
      }
      this.waitApi = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  padding-top: rem(80px);
  padding-bottom: rem(80px);

  text-align: center;
}

.error-message {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.intro {
  width: 90%;
  max-width: rem(300px);

  margin: 0 auto;
}

.measure {
  position: absolute;
  z-index: -99999;

  opacity: 0;
  pointer-events: none;
}

form {
  max-width: 90%;
}

label,
input {
  height: 8vh;

  font-family: 'Fedrigoni-Banner';
  font-size: 7vh;

  font-weight: 500;
  line-height: 8vh;

  html.desktop & {
    height: 11vh;

    font-size: 10vh;
    line-height: 11vh;
  }
}

input {
  width: 100%;

  background: transparent;
  outline: none;
  text-align: center;
  text-decoration: underline;
}

.cta-wrapper {
  position: fixed;
  bottom: rem(16px);
  left: 50%;

  transform: translateX(-50%);

  a {
    font-style: italic;
    text-decoration: underline;
  }
}

.error {
  color: #f00;
}
</style>
